import React, { useState, useEffect } from "react";
import "./Main.css";
import Spinner from "../components/Spinner";
import "../components/Spinner.css";
import ProductList from "../components/ProductList";
import Search from "../components/Search";
import ReactGA from 'react-ga'

function Main() {
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedMunicipality, setSelectedMunicipality] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    ReactGA.initialize('G-1ZE8EWJX58');
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log("GA initialized");
    console.log(ReactGA)
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get("query");
    const province = queryParams.get("province");
    const municipality = queryParams.get("municipality");
    console.log("query", query);
    console.log("province", province);
    console.log("municipality", municipality);
    if (query && province && municipality) {
      setSelectedProvince(province);
      setSelectedMunicipality(municipality);

      search(query, municipality, province);
    }
  }, []);

  const search = async (searchQuery, municipality, province) => {
    try {
      setIsLoading(true);

      ReactGA.event({
        category: 'Search',
        action: 'Results',
        label: `${searchQuery}, ${municipality}, ${province}`
      });

      const response = await fetch(`https://compraencubafast-1-w3965127.deta.app/search?province=${selectedProvince}&municipality=${selectedMunicipality}&query=${searchQuery}`);
      // const response = await fetch(`http://95.216.189.62:8000/search?province=${province}&municipality=${municipality}&query=${searchQuery}`);

      if (response.ok) {
        const data = await response.json();
        setSearchResults(data);
      } else {
        setErrorMessage("An error occurred while fetching the search results.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while fetching the search results.");
    }

    setIsLoading(false);

  }

  const handleSearchButtonClick = async (searchQuery) => {
    setIsLoading(true);
    setErrorMessage("");
    await search(searchQuery, selectedMunicipality, selectedProvince);

  };


  return (
    <div className="main-container">
      <h1 className="title">Busca Cualquier Producto para enviarle a tu familia en Cuba</h1>

      <Search
        selectedProvince={selectedProvince}
        setSelectedProvince={setSelectedProvince}
        selectedMunicipality={selectedMunicipality}
        setSelectedMunicipality={setSelectedMunicipality}
        handleSearchButtonClick={handleSearchButtonClick}
      />

      {isLoading && <Spinner />}

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      {searchResults.length > 0 ? (
        <ProductList products={searchResults} />
      ) : (
        searchResults.length === 0 && !isLoading && (
          <div className="no-results-message">No results found for your search query.</div>
        )
      )}
    </div>
  );
}

export default Main;
