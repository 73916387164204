import React, { useState, useEffect } from "react";
import "./CubaProvincesMunicipalities.css";
const CubanProvinces = [
  { id: 1, name: "Pinar del Río" },
  { id: 2, name: "Artemisa" },
  { id: 3, name: "La Habana" },
  { id: 4, name: "Mayabeque" },
  { id: 5, name: "Matanzas" },
  { id: 6, name: "Cienfuegos" },
  { id: 7, name: "Villa Clara" },
  { id: 8, name: "Sancti Spíritus" },
  { id: 9, name: "Ciego de Ávila" },
  { id: 10, name: "Camagüey" },
  { id: 11, name: "Las Tunas" },
  { id: 12, name: "Holguín" },
  { id: 13, name: "Granma" },
  { id: 14, name: "Santiago de Cuba" },
  { id: 15, name: "Guantánamo" },
  { id: 16, name: "Isla de la Juventud" },
];

const Municipalities = {
  "Pinar del Río": [
    "Consolación del Sur",
    "Guane",
    "La Palma",
    "Los Palacios",
    "Mantua",
    "Minas de Matahambre",
    "Pinar del Río",
    "San Luis",
    "Sandino",
    "Viñales",
  ],
  "Artemisa": [
    "Artemisa",
    "Bahía Honda",
    "Candelaria",
    "Mariel",
    "San Antonio de los Baños",
    "Guanajay",
    "Caimito",
    "Bauta",
    "Alquízar",
    "San Cristóbal",
  ],
  "La Habana": [
    "Habana Vieja",
    "Centro Habana",
    "Regla",
    "Guinness",
    "Arroyo Naranjo",
    "Cotorro",
    "Boyeros",
    "Diez de Octubre",
    "La Habana del Este",
    "Cerro",
    "Plaza de la Revolución",
    "La Lisa",
    "Playa",
    "Marianao",
    "Habana del Oeste",
  ],
  "Mayabeque": [
    "Bejucal",
    "Batabanó",
    "Güines",
    "Jaruco",
    "Madruga",
    "Melena del Sur",
    "Nueva Paz",
    "Quivicán",
    "San José de las Lajas",
    "San Nicolás de Bari",
    "Santa Cruz del Norte",
    "Santo Domingo",
  ],
  "Matanzas": [
    "Cárdenas",
    "Jagüey Grande",
    "Jovellanos",
    "Limonar",
    "Los Arabos",
    "Martí",
    "Matanzas",
    "Pedro Betancourt",
    "Perico",
    "Unión de Reyes",
    "Varadero",
    "Colón",
  ],
  "Villa Clara": [
    "Caibarién",
    "Camajuaní",
    "Cifuentes",
    "Corralillo",
    "Encrucijada",
    "Manicaragua",
    "Placetas",
    "Quemado de Güines",
    "Ranchuelo",
    "Santa Clara",
    "Santo Domingo",
    "Sagua la Grande",
    "Santa Isabel de las Lajas",
    "Esmeralda",
  ],
  "Cienfuegos": [
    "Abreus",
    "Aguada de Pasajeros",
    "Cienfuegos",
    "Cruces",
    "Cumanayagua",
    "Palmira",
    "Rodas",
    "Santa Isabel de las Lajas",
    "Trinidad",
  ],
  "Sancti Spíritus": [
    "Cabaiguán",
    "Fomento",
    "Jatibonico",
    "La Sierpe",
    "Sancti Spíritus",
    "Taguasco",
    "Trinidad",
    "Yaguajay",
    "Cabaiguán",
    "Fomento",
    "Jatibonico",
    "La Sierpe",
    "Sancti Spíritus",
    "Taguasco",
    "Trinidad",
    "Yaguajay",
  ],
  "Ciego de Ávila": [
    "Baraguá",
    "Bolivia",
    "Ciro Redondo",
    "Chambas",
    "Ciego de Ávila",
    "Florencia",
    "Majagua",
    "Morón",
    "Primero de Enero",
    "Venezuela",
  ],
  "Camagüey": [
    "Camagüey",
    "Esmeralda",
    "Florida",
    "Guáimaro",
    "Jimaguayú",
    "Minas",
    "Najasa",
    "Nuevitas",
    "Santa Cruz del Sur",
    "Sibanicú",
    "Sierra de Cubitas",
    "Vertientes",
  ],
  "Las Tunas": [
    "Amancio Rodríguez",
    "Colombia",
    "Jesús Menéndez",
    "Jobabo",
    "Las Tunas",
    "Majibacoa",
    "Manatí",
    "Puerto Padre",
  ],
  "Holguín": [
    "Antilla",
    "Báguanos",
    "Banes",
    "Cacocum",
    "Calixto García",
    "Cueto",
    "Frank País",
    "Gibara",
    "Holguín",
    "Mayarí",
    "Moa",
    "Rafael Freyre",
    "Sagua de Tánamo",
    "Urbano Noris",
    "Sagua de Tánamo",
    "Urbano Noris",
  ],
  "Granma": [
    "Bayamo",
    "Bartolomé Masó",
    "Buey Arriba",
    "Campechuela",
    "Cauto Cristo",
    "Guisa",
    "Jiguaní",
    "Manzanillo",
    "Media Luna",
    "Niquero",
    "Pilón",
    "Río Cauto",
    "Yara",
  ],
  "Santiago de Cuba": [
    "Contramaestre",
    "Guamá",
    "Palma Soriano",
    "San Luis",
    "Santiago de Cuba",
    "Segundo Frente",
    "Tercer Frente",
    "Songo la Maya",
  ],
  "Guantánamo": [
    "Baracoa",
    "Guantánamo",
    "Imías",
    "Maisí",
    "Manuel Tames",
    "Niceto Pérez",
    "San Antonio del Sur",
    "Yateras",
  ],
  "Isla de la Juventud": [
    "Isla de la Juventud"
  ],
};
function CubaProvincesMunicipalities({ selectedProvince, setSelectedProvince, selectedMunicipality, setSelectedMunicipality }) {
  // State to keep track of whether we should automatically set the selected municipality
  const [shouldSetMunicipality, setShouldSetMunicipality] = useState(false);

  // When the selected province changes, set shouldSetMunicipality to true
  useEffect(() => {
    setShouldSetMunicipality(true);
  }, [selectedProvince]);

  // Handler function for when the selected province changes
  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("province", event.target.value);
    window.history.pushState({}, "", "?" + urlParams.toString());
  };

  // Handler function for when the selected municipality changes
  const handleMunicipalityChange = (event) => {
    setSelectedMunicipality(event.target.value);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("municipality", event.target.value);
    window.history.pushState({}, "", "?" + urlParams.toString());
  };

  // Get the list of municipalities for the selected province
  const municipalities = selectedProvince ? Municipalities[selectedProvince] : [];

  // When shouldSetMunicipality or the municipalities list changes, check whether we should automatically set the selected municipality
  useEffect(() => {
    if (shouldSetMunicipality && municipalities.length === 1) {
      // If the selected province only has one municipality, automatically set the selected municipality to that municipality
      setSelectedMunicipality(municipalities[0]);
      // Set shouldSetMunicipality to false
      setShouldSetMunicipality(false);
    }
  }, [shouldSetMunicipality, municipalities, setSelectedMunicipality]);

  // Render the component
  return (
    <div className="provinces-municipalities-container">
      <div>

      <label htmlFor="provinces-select">Provincia:</label>
        <select id="provinces-select" value={selectedProvince} onChange={handleProvinceChange}>
          <option value="">Selecciona una Provincia:</option>
          {CubanProvinces.map((province) => (
            <option key={province.id} value={province.name}>
              {province.name}
            </option>
          ))}
        </select>
      </div>
      <br />
      <div>

        <label htmlFor="municipalities-select">Municipio:</label>
        <select
          id="municipalities-select"
          value={selectedMunicipality}
          onChange={handleMunicipalityChange}
          disabled={!selectedProvince}
        >
          <option value="">Seleccciona un Municipio:</option>
          {municipalities.map((municipality) => (
            <option key={municipality} value={municipality}>
              {municipality}
            </option>
          ))}
        </select>
      </div>
    </div>

  );
}

export default CubaProvincesMunicipalities;