import React, { useState } from "react";
import "./ProductList.css";

function ProductList({ products }) {

  const [currentProduct, setCurrentProduct] = useState(null);

  let images_map = {
    "Supermarket23": "/supermarket23.png",
    "Katapulk": "/katapulk.svg",
  }

  return (
    <div className="product-list">
      {products.map((product, index) => (
        <div
          key={index}
          className="product-item"
        >
          <img className="store-logo" src={images_map[product.store]} alt={product.name} />
          <img className="product-image" src={product.image} alt={product.name} />
          <div className="product-details">
            <h3>{product.name}</h3>
            <p>{product.display_price}</p>
            <p>{product.store}</p>
            <p>{product.shipping}</p>
            {currentProduct === product && product.description && (
              <p className="product-detail">{product.description}</p>
            )}
            <div className="product-buttons">
              <button className="details-button" onClick={() => {

                setCurrentProduct(product);

                if (currentProduct === product) {
                  setCurrentProduct(null);
                }


              }}>
                {currentProduct === product ? "Ver menos" : "Ver más"}
              </button>
              <button className="store-button" onClick={() => {
                window.open(product.url, "_blank");
              }}>Ir a la tienda</button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

}

export default ProductList;
