import CubaProvincesMunicipalities from "../components/CubaProvincesMunicipalities";
import "./Search.css";
import React, { useState, useEffect } from "react";

function Search(props) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const isSearchButtonEnabled = props.selectedProvince && props.selectedMunicipality && searchQuery;

  const handleSearchButtonClick = () => {
    console.log("Search button clicked");
    props.handleSearchButtonClick(searchQuery);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("query", searchQuery);
    window.history.replaceState({}, "", `${window.location.pathname}?${queryParams.toString()}`);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get("query");
    if (query) {
      setSearchQuery(query);
    }
    const province = queryParams.get("province");
    const municipality = queryParams.get("municipality");
    if (province) {
      props.setSelectedProvince(province);
    }
    if (municipality) {
      props.setSelectedMunicipality(municipality);
    }

  }, []);

  return (
    <div className="search-container">

      {/* <label htmlFor="search-bar">Search:</label> */}
      <input type="text" id="search-bar" value={searchQuery} onChange={handleSearchQueryChange} />
      <CubaProvincesMunicipalities
        selectedProvince={props.selectedProvince}
        setSelectedProvince={props.setSelectedProvince}
        selectedMunicipality={props.selectedMunicipality}
        setSelectedMunicipality={props.setSelectedMunicipality}
      />

      <button className="search-button" onClick={handleSearchButtonClick} disabled={!isSearchButtonEnabled} title="Please select a province, a municipality, and enter a search term">
        Buscar
      </button>

    </div>
  );
}

export default Search;
